import { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'concert-ui-library';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Divider } from 'primereact/divider';
import { SelectButton } from 'primereact/selectbutton';
import { InputText } from 'primereact/inputtext';
import { Episode } from '../episodes/slice';
import { ApiCallStatus } from '../../services/api-call-status';
import {
    selectEventCreationStatus,
    selectEventCreationStatusErrorMessage,
} from './calendar-event/calendar-event-selector';
import { SchedulingRequest } from './slice';
import { SchedulingDateFunctions } from '../../services/scheduling-date-fns';
import { ageOfmajority, eventTypeItems, noCommunicationErrorMessages } from './constants';
import calculateUtilization, {
    isNotAcceptingNewPatients,
    isNotAvailableForEpisodeEngagementType,
    getCircleColor,
    UtilizationLegendEnum,
    UtilizationForUser,
    utilizationMessagesConstants,
    isEpisodeOwner,
} from './user-availability-info/utiization-calc';
import { CalendarEventRequest, createCalendarEvent, setCalendarEventIdle } from './calendar-event/calendar-event-slice';
import { AvailabilityProviderDetails } from './availability/slice';
import { UserAvailabilityInfoPanel } from './user-availability-info/user-availability-info-panel';
import { providerByCapacitySorterDescending } from './availability/scheduler-transform';
import { isSubmitKey } from '../../util';

export type SchedulerConfirmationScreenProps = {
    episode: Episode | null;
    users: AvailabilityProviderDetails[];
    selectedSchedulingRequest: SchedulingRequest;
    onCalendarEventRequestComplete: (isSuccess: boolean) => void;
    onGoBack: () => void;
};
enum SchedulingConfirmationStepEnum {
    Initial,
    Conclusion,
}
export const SchedulerConfirmationScreen: FC<SchedulerConfirmationScreenProps> = ({
    users,
    episode,
    selectedSchedulingRequest,
    onCalendarEventRequestComplete,
    onGoBack,
}) => {
    const timeZoneString = SchedulingDateFunctions.getTimezoneDisplayStringFromDate(
        selectedSchedulingRequest.selectedLuxonDate,
    );
    const dispatch = useDispatch();
    const [selectedUser, setSelectedUser] = useState(users.length > 1 ? null : users[0]);
    const [selectedUserUtil, setSelectedUserUtil] = useState(
        selectedUser != null
            ? calculateUtilization(
                  {
                      ...selectedUser.provider,
                      acceptingNewPatients: selectedUser.acceptingNewPatients,
                      notAcceptingType: selectedUser.notAcceptingType,
                  },
                  episode as Episode,
              )
            : null,
    );
    const [episodeAssignedToUser, setEpisodeAssignedToUser] = useState(selectedUser);
    const [eventType, setEventType] = useState('');
    const [schedulingStep, setSchedulingStep] = useState(SchedulingConfirmationStepEnum.Initial);
    const [notes, setNotes] = useState('');
    const [reassignCheck, setReassignCheck] = useState<boolean>(true);
    const [requestResult, setRequestResult] = useState<ApiCallStatus>(ApiCallStatus.idle);
    const [noNotificationMessage, setNoNotificationMessage] = useState<any>(null);
    const eventCreationStatus = useSelector(selectEventCreationStatus);
    const eventCreatingErrorMessage = useSelector(selectEventCreationStatusErrorMessage);
    const onSelectedUser = (value: any) => {
        setSelectedUser(value);
        setSelectedUserUtil(
            value === null
                ? null
                : calculateUtilization(
                      {
                          ...value.provider,
                          acceptingNewPatients: value.acceptingNewPatients,
                          notAcceptingType: value.notAcceptingType,
                      },
                      episode as Episode,
                      true,
                  ),
        );
        setEpisodeAssignedToUser(value);
    };
    const isNotAvailableToBook = (providerDetails: AvailabilityProviderDetails) => {
        return (
            !isEpisodeOwner(providerDetails, episode as Episode) &&
            (isNotAcceptingNewPatients(providerDetails) ||
                isNotAvailableForEpisodeEngagementType(providerDetails, episode as Episode))
        );
    };

    const showNoEmailMessage = useCallback(() => {
        const emailValidations =
            (episode?.patientAge && episode?.patientAge < ageOfmajority) ||
            episode?.isEmailBounced ||
            !episode?.patientEmail?.trim() ||
            episode?.appointmentNotificationsEmailOptOut;
        const smsValidations =
            (!episode?.patientPhone?.trim() && !episode?.patientMobilePhone?.trim()) ||
            episode?.appointmentNotificationsSmsOptOut;

        if (emailValidations && smsValidations) {
            setNoNotificationMessage(noCommunicationErrorMessages.noNotificationWillBeSent);
            return;
        }
        setNoNotificationMessage(null);
    }, [episode]);

    const userItemEls = useRef<Map<string, OverlayPanel | null>>(new Map());
    const useritemTemplate = (availabilityProviderDetail: AvailabilityProviderDetails) => {
        const user = {
            ...availabilityProviderDetail.provider,
            acceptingNewPatients: availabilityProviderDetail.acceptingNewPatients,
            notAcceptingType: availabilityProviderDetail.notAcceptingType,
            capacity: availabilityProviderDetail.capacity,
        };
        const providerLanguagesArray = availabilityProviderDetail.provider.languages?.split(',') || [];
        const util: UtilizationForUser = calculateUtilization(user, episode as Episode);
        const utilCircleClassName = getCircleColor(util.messageCategory);

        const showUserProfile = (
            e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
        ): false => {
            userItemEls.current.get(user.id)?.show(e, e.target);
            e.stopPropagation();
            return false;
        };

        return (
            <div className={`user-item ${user.id}`} data-id={user.id}>
                <i
                    className="pi pi-user user-profile-trigger"
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => showUserProfile(e)}
                    onKeyUp={(e: React.KeyboardEvent<HTMLElement>) => {
                        if (isSubmitKey(e)) return showUserProfile(e);
                        return true;
                    }}
                    tabIndex={0}
                    role="button"
                    aria-label="View User Profile"
                />

                <div className="user-item-name provider-item-name" role="label">
                    {user.nameWithSuffix} ({user.numOfActiveEpisodesForPractice})
                </div>
                <div className="user-item-icons">
                    {isEpisodeOwner(availabilityProviderDetail, episode as Episode) ? (
                        <i className="util pi pi-users" title="Episode Owner" />
                    ) : (
                        <div />
                    )}

                    {availabilityProviderDetail.isOnSitePractice ? (
                        <i className="util pi pi-map-marker" title="On Site" />
                    ) : (
                        <div />
                    )}
                    {providerLanguagesArray.length > 1 ? (
                        <i
                            className="util pi pi-globe"
                            title={availabilityProviderDetail.provider.languages}
                            data-testid="language-icon"
                        />
                    ) : (
                        <div />
                    )}
                    <i className={`util pi pi-circle-on ${utilCircleClassName}`} />
                </div>
            </div>
        );
    };

    const flattenedUsers = users
        .map((providerDetails) => {
            return {
                ...providerDetails,
                ...providerDetails.provider,
                isNotAvailable: isNotAvailableToBook(providerDetails),
                capacity: providerDetails.capacity,
            };
        })
        .sort(providerByCapacitySorterDescending);

    const reassignMessage = () => {
        if (!showReassignCheckbox()) return null;
        if (reassignCheck) {
            return <div role="alert">Episode will be reassigned to {selectedUser?.provider.name}</div>;
        }
        return <div role="alert">Episode not reassigned</div>;
    };

    const renderUserSelectionForAppointment = () => {
        return (
            <div>
                {schedulingStep === SchedulingConfirmationStepEnum.Conclusion ? (
                    <>
                        <h3>
                            {episode ? (episode as Episode).name : ''} Scheduled With {selectedUser?.provider.name}
                        </h3>
                        {reassignMessage()}
                        {eventCreatingErrorMessage && (
                            <span className="no-email-warning" role="alert">
                                {' '}
                                {eventCreatingErrorMessage}{' '}
                            </span>
                        )}
                    </>
                ) : (
                    <>
                        <h3>Schedule {episode ? (episode as Episode).name : ''} With</h3>
                        <div className="user-selector">
                            <SelectButton
                                value={selectedUser}
                                className="user-selector-buttons"
                                optionLabel="name"
                                optionDisabled="isNotAvailable"
                                options={flattenedUsers}
                                dataKey="id"
                                itemTemplate={useritemTemplate}
                                onChange={({ value }: any) => {
                                    onSelectedUser(value);
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    };

    const bookTimeSlot = useCallback(() => {
        const filterWhat = episode;
        const episodeAssigment =
            !isEpisodeOwner(episodeAssignedToUser, filterWhat) && reassignCheck
                ? episodeAssignedToUser?.provider.id ?? null
                : null;
        const calendarEventRequest: CalendarEventRequest = {
            startDateTime: selectedSchedulingRequest.startDateTime.toUTC().toJSON(),
            endDateTime: selectedSchedulingRequest.endDateTime.toUTC().toJSON(),
            whoId: selectedUser?.provider.id || '',
            episodeId: (filterWhat as any).id,
            subject: eventType,
            notes,
            eventType,
            episodeAssignedToUserId: episodeAssigment,
        };
        dispatch(createCalendarEvent(calendarEventRequest));
    }, [selectedUser, episodeAssignedToUser, notes, eventType, reassignCheck]);

    useEffect(() => {
        if (eventCreationStatus === ApiCallStatus.succeeded) {
            dispatch(setCalendarEventIdle());
            setSchedulingStep(SchedulingConfirmationStepEnum.Conclusion);
            setRequestResult(eventCreationStatus);
        }
        if (eventCreationStatus === ApiCallStatus.failed) {
            dispatch(setCalendarEventIdle());
            onCalendarEventRequestComplete(false);
            setRequestResult(eventCreationStatus);
        }
    }, [eventCreationStatus]);

    useEffect(() => {
        showNoEmailMessage();
    }, [selectedUser, eventType, eventCreatingErrorMessage]);
    const getClassAccordingToUtilization = (utilizationLegend: UtilizationLegendEnum | undefined) => {
        if (
            utilizationLegend === UtilizationLegendEnum.OverCapacityAcceptingNewPatients ||
            utilizationLegend === UtilizationLegendEnum.OverCapacityNotAcceptingNewPatients
        ) {
            return 'p-field attention';
        }
        return utilizationLegend === UtilizationLegendEnum.Unknown ? 'p-field warning' : 'p-field';
    };
    const getUtilizationlabel = (utilizationLegend: UtilizationLegendEnum | undefined) => {
        if (
            utilizationLegend === UtilizationLegendEnum.OverCapacityAcceptingNewPatients ||
            utilizationLegend === UtilizationLegendEnum.OverCapacityNotAcceptingNewPatients
        ) {
            return (
                <span className="attention">
                    {`${selectedUserUtil?.rate}% ${utilizationMessagesConstants.OverCapacity}`}
                </span>
            );
        }
        if (utilizationLegend === UtilizationLegendEnum.Unknown) {
            return <span className="warning">{`${utilizationMessagesConstants.Unknown}`}</span>;
        }
        return '';
    };

    const showReassignCheckbox = (): boolean =>
        !isEpisodeOwner(selectedUser as AvailabilityProviderDetails, episode as Episode);

    return (
        <div className="scheduler-confirmation-screen">
            <div
                className={`section left ${
                    schedulingStep === SchedulingConfirmationStepEnum.Conclusion && 'conclusion-width'
                }`}
            >
                {renderUserSelectionForAppointment()}
            </div>
            <div className="section right">
                {schedulingStep === SchedulingConfirmationStepEnum.Conclusion ? (
                    <h3>{eventType} Confirmed </h3>
                ) : (
                    <h3>Meeting Details</h3>
                )}
                <div style={{ fontWeight: 'bold' }}> {selectedSchedulingRequest.duration.minutes} Minute Meeting</div>
                <div className="text-title">
                    {SchedulingDateFunctions.getDateOnlyDisplayString(selectedSchedulingRequest.selectedLuxonDate)}
                </div>
                <div className="text-title">
                    {SchedulingDateFunctions.getTimeDisplayStringHoursMinutesFromDate(
                        selectedSchedulingRequest.startDateTime,
                    )}{' '}
                    -{' '}
                    {SchedulingDateFunctions.getTimeDisplayStringHoursMinutesFromDate(
                        selectedSchedulingRequest.endDateTime,
                    )}{' '}
                </div>
                <div className="text-title">{timeZoneString}</div>
                <Divider />
                <div className={getClassAccordingToUtilization(selectedUserUtil?.category)}>
                    {schedulingStep === SchedulingConfirmationStepEnum.Initial && (
                        <div className="episode-user-selector">
                            <div className="userSelectorHolder">
                                <div className="field-label">
                                    <label htmlFor="episodeAssignedTo">Schedule With</label>
                                    {getUtilizationlabel(selectedUserUtil?.category)}
                                </div>
                                <InputText
                                    placeholder="Select a User..."
                                    disabled
                                    value={selectedUser ? selectedUser.provider.nameWithSuffix : ''}
                                />
                            </div>
                            <div>
                                <div className="field-label">
                                    <label htmlFor="episodeAssignedTo" id="appointment-type-label">
                                        Appointment Type
                                    </label>
                                </div>
                                <Dropdown
                                    aria-labelledby="appointment-type-label"
                                    id="eventType1"
                                    value={eventType}
                                    options={eventTypeItems}
                                    className="appointment-type-drop"
                                    onChange={({ value }) => setEventType(value)}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {selectedUser != null &&
                    schedulingStep === SchedulingConfirmationStepEnum.Initial &&
                    showReassignCheckbox() && (
                        <div className="reassign-check-box">
                            <input
                                onChange={() => setReassignCheck(!reassignCheck)}
                                checked={reassignCheck}
                                type="checkbox"
                                id={`reassign-user-${selectedUser?.provider?.id}-${eventType}`}
                            />
                            <label htmlFor={`reassign-user-${selectedUser?.provider?.id}-${eventType}`}>
                                Reassign Episode?
                            </label>
                        </div>
                    )}
                <div className="p-field notes">
                    <label htmlFor="notes1">Notes</label>
                    <InputTextarea
                        className="p-component p-inputtext"
                        id="notes1"
                        rows={2}
                        value={notes}
                        disabled={schedulingStep === SchedulingConfirmationStepEnum.Conclusion}
                        onChange={({ target }) => setNotes((target as any).value)}
                    />
                    {noNotificationMessage && schedulingStep === SchedulingConfirmationStepEnum.Initial && (
                        <div className="no-email-message" role="alert">
                            {noNotificationMessage}
                        </div>
                    )}
                </div>
                <div className="scheduler-confirmation-buttons">
                    {schedulingStep === SchedulingConfirmationStepEnum.Conclusion ? (
                        <Button
                            label="OK"
                            className="p-button-primary"
                            onClick={() => onCalendarEventRequestComplete(requestResult === ApiCallStatus.succeeded)}
                        />
                    ) : (
                        <>
                            <Button label="Go Back" className="p-button-secondary" onClick={() => onGoBack()} />
                            <Button
                                label="Schedule"
                                disabled={
                                    eventCreationStatus === ApiCallStatus.loading ||
                                    selectedUser === null ||
                                    isNotAvailableToBook(selectedUser) ||
                                    !episodeAssignedToUser ||
                                    eventType === ''
                                }
                                className="p-button-primary"
                                onClick={() => bookTimeSlot()}
                            />
                        </>
                    )}
                </div>
            </div>
            <div>
                <UserAvailabilityInfoPanel
                    availabilityProviderDetails={users}
                    episode={episode}
                    userItemEls={userItemEls}
                    showCloseButton
                />
            </div>
            {eventCreationStatus === ApiCallStatus.loading ? (
                <div className="loader-overlay">
                    <Loader />
                </div>
            ) : (
                <div />
            )}
        </div>
    );
};
