import { SelectButton } from 'primereact/selectbutton';
import { FC } from 'react';
import * as React from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Episode } from '../../episodes/slice';
import calculateUtilization, {
    UtilizationForUser,
    getCircleColor,
    isEpisodeOwner,
} from '../user-availability-info/utiization-calc';
import { User } from '../../user/slice';
import { SlotForRender } from './scheduler-transform';
import { AvailabilityProviderDetails } from './slice';
import { isSubmitKey } from '../../../util';

export type SlotButtonAction = {
    action: string;
    provider: string;
    value: string;
};

export type ProviderSelectorProps = {
    availabilityProviderDetail: User;
    filter: Episode;
    userItemEls: React.MutableRefObject<Map<string, OverlayPanel | null>>;
    showSlots: boolean;
    selectedTimeSlot: SlotButtonAction | string | null;
    selectTimeSlot?: (e: SlotButtonAction | string, providerFilter?: string) => void;
    itemTemplate?: (slots: SlotForRender) => JSX.Element;
};

export const ProviderSelector: FC<ProviderSelectorProps> = ({
    availabilityProviderDetail,
    filter,
    userItemEls,
    showSlots,
    selectedTimeSlot,
    selectTimeSlot,
    itemTemplate,
}) => {
    const util: UtilizationForUser = calculateUtilization(availabilityProviderDetail, filter);
    const utilCircleClassName = getCircleColor(util.messageCategory);

    const selectUser = (e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>): false => {
        userItemEls.current.get(availabilityProviderDetail.id)?.show(e, e.target);
        e.stopPropagation();
        return false;
    };

    const providerLanguagesArray = availabilityProviderDetail.languages?.split(',') || [];

    return (
        <div className="user-selector">
            <div className="p-selectbutton p-buttonset p-component user-selector-buttons" role="group">
                <div className="p-button p-component">
                    <div
                        className={`.user-selector user-item ${availabilityProviderDetail.id}`}
                        data-id={availabilityProviderDetail.id}
                    >
                        <i
                            className="pi"
                            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => selectUser(e)}
                            onKeyUp={(e: React.KeyboardEvent<HTMLElement>) => {
                                if (isSubmitKey(e)) return selectUser(e);
                                return true;
                            }}
                            tabIndex={0}
                            role="button"
                        >
                            <img
                                className="profile-photo-miniature"
                                src={availabilityProviderDetail.photoUrl}
                                alt="avatar"
                            />
                        </i>

                        <div className="provider-item-name" role="label">
                            {availabilityProviderDetail.nameWithSuffix} (
                            {availabilityProviderDetail.numOfActiveEpisodesForPractice})
                        </div>
                        <div className="user-item-icons">
                            {isEpisodeOwner(
                                availabilityProviderDetail.refProvider as AvailabilityProviderDetails,
                                filter,
                            ) && <i className="util pi pi-users" title="Episode Owner" />}
                            {availabilityProviderDetail.isOnSitePractice === true ? (
                                <i className="util pi pi-map-marker" title="On Site" />
                            ) : (
                                <div />
                            )}
                            {providerLanguagesArray.length > 1 ? (
                                <i
                                    className="util pi pi-globe"
                                    title={availabilityProviderDetail.languages}
                                    data-testid="language-icon"
                                />
                            ) : (
                                <div />
                            )}
                            <i className={`util pi pi-circle-on ${utilCircleClassName}`} />
                        </div>
                    </div>
                </div>
            </div>
            {showSlots && (
                <SelectButton
                    options={availabilityProviderDetail.slots}
                    value={selectedTimeSlot}
                    itemTemplate={itemTemplate}
                    optionLabel="label"
                    optionValue="value"
                    className="timeslot-selector"
                    onChange={(e) => selectTimeSlot?.(e.value, availabilityProviderDetail.id)}
                />
            )}
        </div>
    );
};
